// Generated by Framer (abcfa95)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const cycleOrder = ["ME6G5s3tw", "Acz569hnk", "gkVI1FtpS"];

const serializationHash = "framer-ALeSY"

const variantClassNames = {Acz569hnk: "framer-v-1mpbsye", gkVI1FtpS: "framer-v-q2mguc", ME6G5s3tw: "framer-v-go0s7h"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableEnumMap = {Bold: "bold", Duotone: "duotone", Fill: "fill", Light: "light", Regular: "regular", Thin: "thin"}

const humanReadableVariantMap = {Default: "ME6G5s3tw", Muted: "Acz569hnk", Primary: "gkVI1FtpS"}

const getProps = ({height, icon, iconWeight, id, width, ...props}) => { return {...props, Fs_pUO3G8: humanReadableEnumMap[iconWeight] ?? iconWeight ?? props.Fs_pUO3G8 ?? "light", n6Yaek3Qu: icon ?? props.n6Yaek3Qu ?? "Check", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ME6G5s3tw"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;icon?: string;iconWeight?: keyof typeof humanReadableEnumMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, n6Yaek3Qu, Fs_pUO3G8, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ME6G5s3tw", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-go0s7h", className, classNames)} data-border data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"ME6G5s3tw"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-68c05b50-ca7b-4173-82aa-ed42aea1a9b4, rgba(218, 197, 167, 0.15))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: 500, borderBottomRightRadius: 500, borderTopLeftRadius: 500, borderTopRightRadius: 500, ...style}} variants={{Acz569hnk: {"--border-color": "var(--token-68c05b50-ca7b-4173-82aa-ed42aea1a9b4, rgb(51, 51, 48))", backgroundColor: "var(--token-cd2934a7-4e35-4347-a32c-9650fca4db23, rgb(242, 242, 242))"}, gkVI1FtpS: {"--border-bottom-width": "0px", "--border-left-width": "0px", "--border-right-width": "0px", "--border-top-width": "0px", backgroundColor: "var(--token-dca875b7-f855-43c1-bf73-97596e452266, rgb(203, 251, 69))"}}} {...addPropertyOverrides({Acz569hnk: {"data-framer-name": "Muted"}, gkVI1FtpS: {"data-framer-name": "Primary"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-1vxjf35-container"} layoutDependency={layoutDependency} layoutId={"sQCP4JXCL-container"}><Phosphor color={"var(--token-efbc6c56-80fe-4475-9b37-9684d6e92632, rgb(21, 21, 21))"} height={"100%"} iconSearch={"House"} iconSelection={n6Yaek3Qu} id={"sQCP4JXCL"} layoutId={"sQCP4JXCL"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={Fs_pUO3G8} width={"100%"} {...addPropertyOverrides({gkVI1FtpS: {color: "var(--token-f82457c5-8438-48a1-bcce-dde3448a0d3a, rgb(255, 255, 255))"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ALeSY.framer-1m4h2yl, .framer-ALeSY .framer-1m4h2yl { display: block; }", ".framer-ALeSY.framer-go0s7h { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 32px; height: 36px; justify-content: center; overflow: visible; padding: 8px 12px 8px 12px; position: relative; width: 36px; }", ".framer-ALeSY .framer-1vxjf35-container { flex: none; height: 18px; position: relative; width: 18px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-ALeSY.framer-go0s7h { gap: 0px; } .framer-ALeSY.framer-go0s7h > * { margin: 0px; margin-bottom: calc(32px / 2); margin-top: calc(32px / 2); } .framer-ALeSY.framer-go0s7h > :first-child { margin-top: 0px; } .framer-ALeSY.framer-go0s7h > :last-child { margin-bottom: 0px; } }", ".framer-ALeSY[data-border=\"true\"]::after, .framer-ALeSY [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 36
 * @framerIntrinsicWidth 36
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Acz569hnk":{"layout":["fixed","fixed"]},"gkVI1FtpS":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"n6Yaek3Qu":"icon","Fs_pUO3G8":"iconWeight"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerTzOsoroIv: React.ComponentType<Props> = withCSS(Component, css, "framer-ALeSY") as typeof Component;
export default FramerTzOsoroIv;

FramerTzOsoroIv.displayName = "Badge (Default)";

FramerTzOsoroIv.defaultProps = {height: 36, width: 36};

addPropertyControls(FramerTzOsoroIv, {variant: {options: ["ME6G5s3tw", "Acz569hnk", "gkVI1FtpS"], optionTitles: ["Default", "Muted", "Primary"], title: "Variant", type: ControlType.Enum}, n6Yaek3Qu: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "Check", description: undefined, hidden: undefined, title: "Icon"}, Fs_pUO3G8: PhosphorControls?.["weight"] && {...PhosphorControls["weight"], defaultValue: "light", description: undefined, hidden: undefined, title: "Icon Weight"}} as any)

addFonts(FramerTzOsoroIv, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})